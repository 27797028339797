import React, { useState } from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import imageCompression from 'browser-image-compression';
import * as gifuct from 'gifuct-js'


const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    overflow-x: hidden;
    background: #0a0a0a;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
  color: #00f7ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  text-shadow: 0 0 10px #00f7ff;
  margin-bottom: 2rem;
  text-align: center;
`;

const ConverterBox = styled.div`
  background: rgba(0, 0, 0, 0.8);
  padding: 2.5rem;
  border-radius: 20px;
  border: 1px solid #00f7ff;
  box-shadow: 0 0 30px rgba(0, 247, 255, 0.15);
  width: 100%;
  max-width: 400px;
  backdrop-filter: blur(10px);
`;

const FileInput = styled.input`
  display: none;
`;

const FileButton = styled.button`
  background: transparent;
  color: #00f7ff;
  border: 2px solid #00f7ff;
  padding: 1rem 2rem;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.1rem;
  width: 100%;
  transition: all 0.3s ease;
  text-shadow: 0 0 5px #00f7ff;
  
  &:hover {
    background: rgba(0, 247, 255, 0.1);
    box-shadow: 0 0 20px rgba(0, 247, 255, 0.4);
    transform: translateY(-2px);
  }
`;

const Select = styled.select`
  background: rgba(0, 0, 0, 0.6);
  color: #00f7ff;
  border: 2px solid #00f7ff;
  padding: 0.8rem;
  border-radius: 10px;
  margin: 1.5rem 0;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background: rgba(0, 247, 255, 0.1);
  }
  
  option {
    background: #111;
  }
`;

const ConvertButton = styled(FileButton)`
  margin-top: 1rem;
`;

const StatusText = styled.p`
  text-align: center;
  margin-top: 1.5rem;
  font-size: 1.1rem;
  min-height: 1.5em;
  opacity: ${props => props.$status ? 1 : 0};
  transition: opacity 0.3s ease;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background: rgba(0, 247, 255, 0.1);
  border-radius: 2px;
  margin-top: 1rem;
  overflow: hidden;
  opacity: ${props => props.$show ? 1 : 0};
  transition: opacity 0.3s ease;
`;

const ProgressFill = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background: #00f7ff;
  transition: width 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 247, 255, 0.5);
`;

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [targetFormat, setTargetFormat] = useState('jpg');
  const [status, setStatus] = useState('');
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setSelectedFiles([]);
      setStatus(`Selected: ${file.name}`);
      setProgress(0);
    }
  };

  const handleFolderSelect = (e) => {
    const files = Array.from(e.target.files).filter(file => 
      file.type.startsWith('image/')
    );
    if (files.length > 0) {
      setSelectedFiles(files);
      setSelectedFile(null);
      setStatus(`Selected ${files.length} images`);
      setProgress(0);
    }
  };

  const extractGifFrames = async (file) => {
    try {
      setIsProcessing(true);
      const zip = new JSZip();
      
      // Parse and decompress the GIF frames
      const buffer = await file.arrayBuffer();
      const gif = gifuct.parseGIF(buffer);
      const frames = gifuct.decompressFrames(gif, true); // Use the separate decompressFrames function
      
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = frames[0].dims.width;
      canvas.height = frames[0].dims.height;
      
      
      
      // Create temporary canvas for building frames
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      tempCanvas.width = canvas.width;
      tempCanvas.height = canvas.height;
      
      // Initialize cache for frame data
      let imageData = ctx.createImageData(canvas.width, canvas.height);
      
      for (let i = 0; i < frames.length; i++) {
        const frame = frames[i];
        
        // Draw this frame's patch data
        imageData = ctx.createImageData(canvas.width, canvas.height);
        imageData.data.set(frame.patch);
        tempCtx.putImageData(imageData, 0, 0);
        
        // Copy to main canvas
        ctx.drawImage(tempCanvas, frame.dims.left, frame.dims.top);
        
        await new Promise(resolve => {
          canvas.toBlob(async (blob) => {
            const compressedBlob = await imageCompression(blob, {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true
            });
            
            zip.file(`frame-${i + 1}.${targetFormat}`, compressedBlob);
            setProgress((i + 1) / frames.length * 100);
            resolve();
          }, `image/${targetFormat}`);
        });
      }
  
      setStatus('Generating ZIP file...');
      const content = await zip.generateAsync({ 
        type: 'blob',
        compression: "DEFLATE",
        compressionOptions: { level: 6 }
      }, (metadata) => {
        setProgress(metadata.percent);
      });
      
      saveAs(content, `gif-frames.zip`);
      setStatus('✅ GIF frames extracted and converted!');
      
      setTimeout(() => {
        setIsProcessing(false);
        setProgress(0);
      }, 2000);
  
    } catch (error) {
      console.error(error);
      setStatus('❌ Failed to process GIF frames');
      setIsProcessing(false);
      setProgress(0);
    }
  };

  const handleConvert = async () => {
    if (!selectedFile && selectedFiles.length === 0) {
      setStatus('Please select a file or folder first');
      return;
    }

    try {
      setIsProcessing(true);
      setStatus('Converting...');
      setProgress(0);

      // Handle GIF to frames conversion
      if (selectedFile?.type === 'image/gif' && targetFormat !== 'gif') {
        await extractGifFrames(selectedFile);
        return;
      }

      // Handle multiple files from folder
      if (selectedFiles.length > 0) {
        const zip = new JSZip();
        let completed = 0;

        for (const file of selectedFiles) {
          const blob = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = async (e) => {
              const img = new Image();
              img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                canvas.toBlob(resolve, `image/${targetFormat}`);
              };
              img.src = e.target.result;
            };
            reader.readAsDataURL(file);
          });

          const compressedBlob = await imageCompression(blob, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
          });

          zip.file(`${file.name.split('.')[0]}.${targetFormat}`, compressedBlob);
          completed++;
          setProgress((completed / selectedFiles.length) * 100);
        }

        const content = await zip.generateAsync({ 
          type: 'blob',
          compression: "DEFLATE",
          compressionOptions: { level: 6 }
        });

        saveAs(content, `converted-images.zip`);
        setStatus('✅ All images converted!');
        
        // Hide progress bar after 2 seconds
        setTimeout(() => {
          setIsProcessing(false);
          setProgress(0);
        }, 2000);
        return;
      }

      // Handle single file conversion
      const reader = new FileReader();
      reader.onload = async (event) => {
        const img = new Image();
        img.src = event.target.result;
        
        await new Promise((resolve) => {
          img.onload = () => {
            setProgress(50);
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            
            canvas.toBlob(async (blob) => {
              const compressedBlob = await imageCompression(blob, {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                onProgress: (p) => setProgress(50 + p * 50)
              });
              
              const url = URL.createObjectURL(compressedBlob);
              const a = document.createElement('a');
              a.href = url;
              a.download = `converted-image.${targetFormat}`;
              a.click();
              URL.revokeObjectURL(url);
              setStatus('✅ Conversion complete!');
              
              // Hide progress bar after 2 seconds
              setTimeout(() => {
                setIsProcessing(false);
                setProgress(0);
              }, 2000);
              resolve();
            }, `image/${targetFormat}`);
          };
        });
      };
      
      reader.readAsDataURL(selectedFile);
    } catch (error) {
      console.error(error);
      setStatus('❌ Conversion failed. Please try again.');
      setIsProcessing(false);
      setProgress(0);
    }
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Title>File Converter</Title>
        <ConverterBox>
          <FileInput 
            type="file" 
            id="fileInput" 
            onChange={handleFileSelect}
            accept="image/*"
          />
          <FileInput 
            type="file" 
            id="folderInput" 
            onChange={handleFolderSelect}
            webkitdirectory="" 
            directory=""
            multiple
            accept="image/*"
          />
          <FileButton as="label" htmlFor="fileInput">
            Choose File
          </FileButton>
          {/* <FileButton as="label" htmlFor="folderInput" style={{marginTop: '1rem'}}>
            Choose Folder
          </FileButton> */}
          <Select 
            value={targetFormat}
            onChange={(e) => setTargetFormat(e.target.value)}
          >
            <option value="jpg">JPG</option>
            <option value="png">PNG</option>
            {/* <option value="gif">GIF</option> */}
            <option value="webp">WEBP</option>
          </Select>
          <ConvertButton onClick={handleConvert}>
            Convert
          </ConvertButton>
          {isProcessing && (
            <ProgressBar $show={isProcessing}>
              <ProgressFill progress={progress} />
            </ProgressBar>
          )}
          <StatusText $status={status}>{status}</StatusText>
        </ConverterBox>
      </Container>
    </>
  );
}

export default App;